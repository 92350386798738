<!-- 发票抬头管理 -->
<template>
  <div id="invoiceTitleList">
    <div>
      <!-- 头部地理位置和搜索框 -->
      <div class="head">
        <!-- 顶部NavBar 开始 -->
        <van-nav-bar
          :title="$t('person.invoice-title')"
          :left-text="$t('main.return')"
          left-arrow
          style="background-color: rgba(255,255,255,0);"
          @click-left="onClickLeft"
        />
      </div>
    </div>
    <div class="outboxstyle">
      <div class="form-part-group">
        <van-field name="radio" label="发票类型" input-align="right">
          <template #input>
            <van-radio-group v-model="custType" direction="horizontal">
              <van-radio name="1">个人</van-radio>
              <van-radio name="2">公司</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-model="custName" label="发票抬头" placeholder="请输入发票抬头" input-align="right" />
        <van-field v-if="custType === '2'" v-model="custTaxNo" label="纳税人识别号" placeholder="请输入纳税人识别号" input-align="right" />
        <van-field v-if="custType === '2'" v-model="custBank" label="开户行" placeholder="请输入开户行" input-align="right" />
        <van-field v-if="custType === '2'" v-model="custBankAccount" label="银行账号" placeholder="请输入银行账号" input-align="right" />
        <van-field v-if="custType === '1'" v-model="custTelephone" label="手机号" placeholder="请输入电话" input-align="right" />
        <van-field v-if="custType === '2'" v-model="custPhone" label="固定电话" placeholder="请输入电话" input-align="right" />
        <van-field v-if="custType === '2'" v-model="custAddr" label="地址" placeholder="请输入地址" input-align="right" />
        <van-field v-if="custType === '1'" v-model="custEmail" label="邮箱" placeholder="请输入邮箱" input-align="right" />
        <van-field name="switch" label="设置为首选抬头" input-align="right">
          <template #input>
            <van-switch v-model="isDefault" size="20" />
          </template>
        </van-field>
      </div>
    </div>
    <div class="bottom">
      <van-button v-if="this.$route.params.id !== 'NO'" type="info" plain size="normal" class="bottombutton" @click="delBillPersonInfo">删除</van-button>
      <van-button type="info" size="normal" class="bottombutton" @click="applyBtn">提交</van-button>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { delBillPersonInfo, addBillPersonInfo, getBillInfoRefById, updateBillPersonInfo } from '@/api/invoiceTitle'
export default {
  name: 'InvoiceTitleList',
  data() {
    return {
      id: '',
      custType: '', // 发票类型
      custName: '', // 发票抬头
      custTaxNo: '', // 纳税人识别号
      custBank: '', // 开户行
      custBankAccount: '', // 银行账号
      custPhone: '', // 固定电话
      custTelephone: '', // 手机号
      custAddr: '', // 地址
      custEmail: '', // 邮箱
      isDefault: '' // 设置默认抬头
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  mounted() {
    if (this.$route.params.id !== 'NO') {
      this.dataFix()
    }
  },
  methods: {
    // 修改赋值
    dataFix() {
      getBillInfoRefById(this.$route.params.id).then(res => {
        this.id = res.data.id
        this.custType = res.data.custType
        this.custName = res.data.custName
        this.custTaxNo = res.data.custTaxNo
        this.custBank = res.data.custBank
        this.custBankAccount = res.data.custBankAccount
        this.custPhone = res.data.custPhone
        this.custTelephone = res.data.custTelephone
        this.custAddr = res.data.custAddr
        this.custEmail = res.data.custEmail
        if (res.data.isDefault === '1') {
          this.isDefault = true
        } else {
          this.isDefault = false
        }
      })
    },
    // 提交
    applyBtn() {
      var data = {}
      data.custType = this.custType
      data.custName = this.custName
      data.custTaxNo = this.custTaxNo
      data.custBank = this.custBank
      data.custBankAccount = this.custBankAccount
      data.custPhone = this.custPhone
      data.custTelephone = this.custTelephone
      data.custAddr = this.custAddr
      data.custEmail = this.custEmail
      data.userId = window.localStorage.getItem('userId')
      if (this.isDefault) {
        data.isDefault = '1'
      } else {
        data.isDefault = '0'
      }
      if (this.$route.params.id === 'NO') {
        addBillPersonInfo(data).then(res => {
          Toast.success('提交成功')
          this.$router.go(-1)
        })
      } else {
        data.id = this.id
        updateBillPersonInfo(data).then(res => {
          Toast.success('提交成功')
          this.$router.go(-1)
        })
      }
    },
    // 删除
    delBillPersonInfo() {
      var that = this
      Dialog.alert({
        title: '提示',
        message: '是否要删除？',
        showCancelButton: true
      }).then(() => {
        delBillPersonInfo(this.$route.params.id).then(function() {
          Toast.success('删除成功')
          that.$router.go(-1)
        })
      })
    },
    // 返回
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped lang="scss">
#invoiceTitleList {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 86px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  padding: 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 80px;
  width: 100%;
  height: 100%;
}
.boxstyle {
  margin: 10px 15px 0 15px;
  padding:20px 13px 0;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 10px #dde9f3;
}
.form-part-group{
  background-color: #fff;
  border-radius: 10px;
  font-size: 14px;
  padding: 0 15px;
}
.bottom{
  width: 100%;
  background: #ffffff;
  padding: 0 20px;
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 0;
  height: 60px;
}
.van-button--info{
  border: 1px solid #2F9FF2;
}
.bottombutton{
  width:100%;
}
.bottombutton:nth-child(2){
  margin-left: 10px;
}
::v-deep .van-cell__title.van-field__label{
  font-size: 12px;
  color: #747474;
}
</style>
<style>
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
</style>
